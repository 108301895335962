import React, {createContext, useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Routes,
} from "react-router-dom";
import {Home} from "./pages/Home";
import {Base} from "./components/Base";
import {Members} from "./pages/Members";
import {NotFound} from "./pages/NotFound";
import {Login} from "./pages/Login";
import {Settings} from "./pages/Settings";
import {IncomeSetup} from "./pages/IncomeSetup";
import {Accounts} from "./pages/Accounts";
// import ProtectedLayout from "./layouts/ProtectedLayout";
import {useAuth} from "./hooks/useAuth";
import {useEnv} from "./hooks/useEnv";
import {ThemeProvider} from "./hooks/useTheme";
import {AuthProvider} from "./hooks/useAuth";
import Groups from "./pages/Groups";
const ThemeContext = createContext();
const AuthContext = createContext();


export const MainApp = (props) => {
    // const [theme, setTheme] = useState('light');
    // const {env} = useEnv();
    // const {authenticated, user} = useAuth();

    // useEffect(() => {
    //     const local_storage_theme = localStorage.getItem('tablerTheme');
    //     if (local_storage_theme) {
    //         setTheme(local_storage_theme);
    //     } else {
    //         setTheme('light');
    //         localStorage.setItem('tablerTheme', 'light');
    //         document.body.setAttribute('data-bs-theme', 'light');
    //     }
    // }, [])

    // const themeChanged = () => {
    //     let newTheme = theme === 'light' ? 'dark' : 'light';
    //     setTheme(newTheme);
    //     localStorage.setItem('tablerTheme', newTheme);
    //     document.body.setAttribute('data-bs-theme', newTheme);
    // }

    return (
        <>
            <ThemeProvider>
                <AuthProvider>
                    <Router>
                        <Routes>


                            <Route path="/" element={<Base />}>
                                <Route path={""} element={<Home />}/>
                                <Route path={"members"} element={<Members />}/>
                                <Route path={"settings"} element={<Settings />}/>
                                <Route path={"finance/income-setup"} element={<IncomeSetup />}/>
                                <Route path={"finance/accounts"} element={<Accounts />}/>
                            </Route>


                            <Route path="/login" element={<Login />}/>
                            <Route path="*" element={<NotFound />}/>

                        </Routes>
                    </Router>
                </AuthProvider>
            </ThemeProvider>
        </>
    );
}


