import React, {useEffect, useState, useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaSave, FaTimes, FaUserPlus, FaUsers} from "react-icons/fa";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";

export const MemberModal = (props) => {
  const [editedMember, setEditedMember] = useState({});
  const {
    show,
    onShow,
    onHide,
    selectedMember,
    theme,
    memberVisits,
    saveEditedMember,
    selectedFamilyChanged,
    setSelectedMember,
  } = props;
  // const editedMemberRef = useRef(editedMember);

  useEffect(() => {
    if(selectedMember) {
      setEditedMember(selectedMember);
    }else if (selectedMember === null) {
      setEditedMember({});
    }
  }, [selectedMember]);


  // useEffect(() => {
  //   editedMemberRef.current = editedMember;
  // }, [editedMember])


  const memberValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditedMember({...editedMember, [name]: value});
  }

  const isMemberUnchanged = () => {
    return JSON.stringify(editedMember) === JSON.stringify(selectedMember);
  }

  const saveMemberClicked = () => {
    let family_id = selectedMember?.family_id;
    saveEditedMember(editedMember).then(data => {
      if(data?.success) {
        toast.success('Member saved successfully');
        selectedFamilyChanged(family_id);
        onHide();
        setSelectedMember(null);
      } else {
        toast.error('Failed to save member');
      }
    });
  }


  return (
    <>
      <Modal
        size={'xl'}
        show={show}
        onHide={onHide}
        centered={true}
        // className={`modal-blur`}
        className={`modal-custom-backdrop-${theme}`}
        backdrop={"static"}
        // keyboard={members?.length === 0}
        // dialogClassName={`box-shadow-${theme}`}

      >
        <Modal.Body className={`box-shadow-${theme} rounded-3`}>
          <div className="row">
            <div className="d-flex col-lg-12">

              <h3>
                Member View
                {/*<span className={"badge bg-primary-lt ms-2"}>{selectedFamily?.members?.length} Member(s)</span>*/}
              </h3>
              <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
            </div>
          </div>
          <div className="row">
            {/*MEMBER INFORMATION*/}
            {/*
              BirthDate
              Initials
              IdNo
              notes
            */}
            <div className="col-lg-6">
              <div className="card">
                <h4 className={"text-center p-1 bg-primary-lt mb-0"}>Information</h4>
                <div className="row p-3">
                  <div className="col-lg-6">
                    <div className="form-group pb-2">
                      <label>Full Names</label>
                      <input onInput={memberValueChanged} type="text" name={"FullNames"} className="form-control" value={editedMember?.FullNames}/>
                    </div>
                    <div className="form-group pb-2">
                      <label>Names</label>
                      <input onInput={memberValueChanged} type="text" name={"Names"} className="form-control" value={editedMember?.Names}/>
                    </div>
                    <div className="form-group pb-2">
                      <label>Surname</label>
                      <input onInput={memberValueChanged} type="text" name={"Surname"} className="form-control" value={editedMember?.Surname}/>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group pb-2">
                      <label>Date of Birth</label>
                      <input
                        onInput={memberValueChanged}
                        type="date"
                        name={"BirthDate"}
                        className="form-control"
                        // defaultValue={editedMember?.BirthDate}
                        value={editedMember?.BirthDate ? editedMember?.BirthDate : ""}
                      />
                    </div>
                    <div className="form-group pb-2">
                      <label>Initials</label>
                      <input onInput={memberValueChanged} type="text" name={"Initials"} className="form-control" value={editedMember?.Initials}/>
                    </div>
                    <div className="form-group pb-2">
                      <label>ID Number</label>
                      <input onInput={memberValueChanged} type="text" name={"IdNo"} className="form-control" value={editedMember?.IdNo}/>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group pb-2">
                      <label>Notes</label>
                      <textarea onInput={memberValueChanged} style={{resize: 'none'}} name={"notes"} rows={3} className="form-control" value={editedMember?.notes}/>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button onClick={saveMemberClicked} className={`btn btn-${isMemberUnchanged() ? 'secondary' : 'primary'} w-100`}>
                      <FaSave className={"me-2"}/>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/*MEMBER VISITS*/}
            <div className="col-lg-6">
              <div className="card">
                <h4 className={"text-center p-1 bg-primary-lt mb-0"}>Visits</h4>
                <div className="row">
                  {memberVisits?.length === 0 && (
                    <div className="col-12">
                      <div className="card card-table pt-3">
                          <h4 className={"fw-bold text-center text-muted"}>No Visits</h4>
                      </div>
                    </div>
                  )}
                  {memberVisits?.map((visit, visitIndex) => {
                    return (
                      <div className="col-12" key={visitIndex}>
                        <div className={"m-2"}>
                          <div className="card card-table pt-3">
                            <table className="table table-borderless table-striped">
                              <tr>
                                <td className={"fw-bold"}>Visit Date</td>
                                <td>
                                  <span className={"badge bg-info-lt p-1"}>{visit?.dt_created}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className={"fw-bold"}>Visited by</td>
                                <td>
                                  <span className={"badge bg-warning-lt p-1"}> {visit?.visiting_user?.FullNames}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className={"fw-bold"}>Visit Reason</td>
                                <td>{visit?.reason?.name}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}