import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useTheme} from "../hooks/useTheme";

export const ConfirmModal = (props) => {
  const {
    show,
    onHide,
    onConfirm,
    onCancel,
    title,
    message,
  } = props;
  const {theme} = useTheme();


  return (
    <>

      <Modal
        size={'xl'}
        show={show}
        onHide={onHide}
        // className={"modal-blur"}
        className={`modal-custom-backdrop-${theme}`}
        // centered={true}
      >
        <Modal.Body>
          <div className="row">
            <div className="d-flex col-lg-12">
              <h3>
                {title}
              </h3>
              <button className={"btn btn-outline-primary ms-auto"} onClick={onCancel}>
                Cancel
              </button>
              <button className={"btn btn-primary ms-2"} onClick={onConfirm}>
                Confirm
              </button>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              {message}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}