import React, {useEffect, useRef, useState} from 'react';
import {useEnv} from "../hooks/useEnv";
import {useAuth} from "../hooks/useAuth";
import {useWebsocket} from "../hooks/useWebsocket";
import {getCookie} from "../func/Func";
import {FaUpload} from "react-icons/fa";
import toast from "react-hot-toast";


const darkBg = {
  backgroundColor: '#151f2c',
}

export const Settings = (props) => {
  const {user} = useAuth();
  const [databaseFile, setDatabaseFile] = useState(null);
  const {ws_send, ws_status, message, initWebsocket, ws_connected} = useWebsocket(3103);
  const [uploadData, setUploadData] = useState(null);


  useEffect(() => {
    initWebsocket(user);
  }, []);


  useEffect(() => {
    if (message) {
      // console.log(typeof(message), message);
      if (message?.type === "import") {
        setUploadData(message?.data);
      }
    }
  }, [message])


  const uploadDatabaseFile = () => {
    if (!databaseFile) {
      toast.error('Please select a file to upload');
    } else {
      const formData = new FormData();
      formData.append("file", databaseFile);
      fetch(`/apiv2/upload_legacy_database/`, {
        method: 'POST',
        headers: {
          "X-CSRFToken": getCookie('csrftoken'),
        },
        body: formData
      }).then(res => res.json())
        .then(data => {
          if(data?.success) {
            toast.success(data?.message);
          }else{
            toast.error(data?.message);
          }
        })
        .catch(err => {
          toast.error('Failed to upload file');
          console.log(err);
        })
    }
  }

  const databaseFileChanged = (e) => {
    setDatabaseFile(e.target.files[0]);
  }

  const ProgressBar = (props) => {
    const {uploadData} = props;
    let bg_color = "";
    if(uploadData?.error){
      bg_color = "danger";
    }else if(uploadData?.success) {
      bg_color = "success";
    }else{
      bg_color = "primary";
    }

    return (
      <div className="progress">
        <div className={`progress-bar bg-${bg_color}`}
             style={{width: `${uploadData?.progress}%`}} role="progressbar" aria-valuenow="38"
             aria-valuemin="0" aria-valuemax="100" aria-label="38% Complete">
          <span className="visually-hidden">38% Complete</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">


          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="card">
              <div className="row">
                <div className="col-lg-12 p-2 px-4">
                  <div>
                    <h2 className={"m-0"}>Database Settings</h2>
                  </div>
                </div>
                <div className="col-lg-12">
                <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="">Import Database</label>
                      <input type="file" className="form-control" onChange={databaseFileChanged}/>
                    </div>

                    <div className="card mt-3 p-3">

                      <h4 className={"mb-2"}>
                        {uploadData?.error ?
                          uploadData?.error_message
                          :
                          uploadData?.message
                        }
                      </h4>

                      {/*<div className="progress">*/}
                      {/*  <div className={`progress-bar bg-${uploadData?.error ? 'danger' : ''}`}*/}
                      {/*       style={{width: `${uploadData?.progress}%`}} role="progressbar" aria-valuenow="38"*/}
                      {/*       aria-valuemin="0" aria-valuemax="100" aria-label="38% Complete">*/}
                      {/*    <span className="visually-hidden">38% Complete</span>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      <ProgressBar uploadData={uploadData}/>

                    </div>


                  </div>
                  <div className="card-footer p-2">
                    <button disabled={databaseFile ? false : true}
                            className={`btn btn-${databaseFile ? 'primary' : 'secondary'} w-100`}
                            onClick={uploadDatabaseFile}>
                      <FaUpload className={"me-1"}/>
                      UPLOAD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}