import React, {useEffect, useState} from 'react';
import {useEnv} from "../hooks/useEnv";
import {useAuth} from "../hooks/useAuth";
import {useWebsocket} from "../hooks/useWebsocket";
import {FaUsers, FaAt, FaEnvelope, FaTelegram, FaMessage, FaRegMessage, FaWhatsapp} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import BirthdayReminder from "./BirthdayReminder";
import UpComingEvents from "./UpComingEvents";


export const Home = (props) => {
  // const {env} = useEnv();
  const navigate = useNavigate();
  const {user} = useAuth();
  // const {ws_send, ws_status, message, initWebsocket, ws_connected} = useWebsocket(env);
  // console.log("Home.js", authenticated, user);
  const [stats, setStats] = useState({});

  useEffect(() => {
    // initWebsocket();
    getStats();
  }, []);

  const getStats = () => {
    fetch(`/apiv2/stats/`, {
      method: 'GET',
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          setStats(data?.data);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const gotoMembers = () => {
    navigate('/members');
  }

  return (
    <>
      <div className="container-fluid">
        <h1 className={"text-center text-muted"}>{stats?.company?.name}</h1>
        <div className="row">

          <div className="col-xl-3 col-sm-3 col-md-3 mb-3">
            <div className="card p-3 cursor-pointer" onClick={gotoMembers}>
              <div className="row">
                <div className="col-5 d-flex justify-content-center align-items-center">
                  <FaUsers color={"#4299e1"} size={60}/>
                </div>
                <div className="col-5">
                  <h2 className={"mt-2 mb-0"}>{stats?.total_members}</h2>
                  <h2 className={"mb-2 mt-0"}>Total Members</h2>
                </div>
              </div>
            </div>
          </div>


          <div className="col-xl-2 col-lg-2 col-sm-3 col-md-3 mb-3">
            <div className="card p-3 cursor-pointer">
              <div className="row">
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <FaEnvelope color={"#0ca678"} size={60}/>
                  {/*<FaAt color={"#4299e1"} size={60}/>*/}
                </div>
                <div className="col-6">
                  <h2 className={"mt-2 mb-0"}>{stats?.company?.email_credits}</h2>
                  <h2 className={"mb-2 mt-0"}>Email Credits</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-2 col-lg-2 col-sm-3 col-md-3 mb-3">
            <div className="card p-3 cursor-pointer">
              <div className="row">
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <FaRegMessage color={"#f59f00"} size={60}/>
                </div>
                <div className="col-6">
                  <h2 className={"mt-2 mb-0"}>{stats?.company?.sms_credits}</h2>
                  <h2 className={"mb-2 mt-0"}>SMS Credits</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="col xl-3 col-lg-2 col-sm-3 col-md-3 mb-3">
            <div className="card p-1 cursor-pointer">
              <div className="row">
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <FaTelegram color={"#4299e1"} size={60}/>
                </div>
                <div className="col-6">
                  <h2 className={"mt-2 mb-0"}>{stats?.company?.telegram_credits}</h2>
                  <h2 className={"mb-2 mt-0"}>Telegram Credits</h2>
                </div>
              </div>
            </div>
          </div>


          <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 mb-3">
            <div className="card p-3 cursor-pointer">
              <div className="row">
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <FaWhatsapp color={"#0ca678"} size={60}/>
                  {/*<FaAt color={"#4299e1"} size={60}/>*/}
                </div>
                <div className="col-6">
                  <h2 className={"mt-2 mb-0"}>{stats?.company?.whatsapp_credits}</h2>
                  <h2 className={"mb-2 mt-0"}>Whatsapp Credits</h2>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-3 col-sm-6 col-md-6 mb-3">
            <div className="card p-3 cursor-pointer">
              <div className="row">
                <div>
                  <BirthdayReminder color={"#4299e1"} size={60} user={user}/>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6 mb-3">
            <div className="card p-3 cursor-pointer">
              <div className="row">
                <div>
                  <UpComingEvents color={"#4299e1"} size={60} user={user}/>
                </div>
              </div>
            </div>
          </div>


          {/*<div className="col-lg-3 col-sm-6 col-md-6 mb-3">*/}
          {/*  <div className="card p-3 d-flex align-content-center justify-content-center">*/}
          {/*    <FaEnvelope className={"m-auto"} size={40}/>*/}
          {/*    <h2 className={"text-center mb-0 mt-2"}>{stats?.company?.sms_credits}</h2>*/}
          {/*    <h2 className={"text-center mb-2 mt-2"}>SMS Credits</h2>*/}
          {/*  </div>*/}
          {/*</div>*/}

        </div>
      </div>
    </>
  )
}