import React, {useEffect, useState} from 'react';

export const NotFound = (props) => {
    return (
        <>
            <div className="container-fluid">
                <h1>Page not found</h1>
            </div>
        </>
    )
}