import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaSearch} from "react-icons/fa";

export const SearchModal = (props) => {
    const {
        show,
        handleCloseSearchModal,
        handleShowSearchModal,
        searchChanged,
        memberSearch,
      theme
    } = props;


    return (
        <>

            <Modal
                size={'xl'}
                show={show}
                onHide={handleCloseSearchModal}
                centered={true}
                className={`modal-custom-backdrop-${theme}`}
                // className={"modal-blur"}
            >
                <Modal.Body>
                    <h3>Search</h3>
                    <div className="input-icon">
                        <input onInput={searchChanged} type="text" value={memberSearch} className="form-control" placeholder="Search…"/>
                        <span className="input-icon-addon">
                            <FaSearch/>
                        </span>
                    </div>

                </Modal.Body>
                {/*<Modal.Footer>*/}
                {/*    <button className="btn btn-secondary" onClick={handleCloseSearchModal}>*/}
                {/*        Close*/}
                {/*    </button>*/}
                {/*    <button className="btn btn-primary" onClick={handleCloseSearchModal}>*/}
                {/*        Save Changes*/}
                {/*    </button>*/}
                {/*</Modal.Footer>*/}
            </Modal>
        </>
    );
}