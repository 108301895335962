import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Link} from "react-router-dom";
import {FaHouse, FaMoneyBills, FaUsers, FaGear} from "react-icons/fa6";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";

const textStyle = {
  fontSize: '1.3rem',
}

export const NavModal = (props) => {
  const {
    show,
    handleCloseNavModal,
    handleShowNavModal,
    PageLogo,
    theme
  } = props;


  const DropdownMenu = (props) => {
    const [expanded, setExpanded] = useState(false);
    const {title, className, style} = props;
    return (
      <>
        <a className={`${className}`} onClick={() => setExpanded(!expanded)}>
          <span>
            <span className={"cursor-pointer d-flex align-items-center flex-row"} style={style}>
                {title}
                {expanded ? (
                  <FaAngleUp/>
                ) : (
                  <FaAngleDown/>
                )}
            </span>
          </span>
        </a>
        {expanded && (
          <div className="bg-muted-lt">
            {props?.children?.map((child, index) => {
              return (
                <div key={index} onClick={handleCloseNavModal}>
                  {child}
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }


  return (
    <>

      <Modal
        size={'xl'}
        show={show}
        onHide={handleCloseNavModal}
        className={`modal-custom-backdrop-${theme}`}
        // className={"modal-blur"}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <PageLogo/>
            </div>
            <div className="col-lg-12">
              <div className="d-flex flex-column mt-3">

                <DropdownMenu className={"dropdown-item p-2 py-2"} title={<>
                  <FaMoneyBills size={20}/>
                  <span className="ms-2" style={textStyle}>Finances</span>
                </>}>
                  <Link to={"/finance/accounts"} className="dropdown-item p-2 py-2 ms-4" style={textStyle}>
                    Accounts
                  </Link>
                  <Link to={"/expenses"} className="dropdown-item p-2 py-2 ms-4" style={textStyle}>
                    Expense
                  </Link>
                  <Link to={"/finance/income-setup"} className="dropdown-item p-2 py-2 ms-4" style={textStyle}>
                    Income Setup
                  </Link>
                </DropdownMenu>

                <Link to={"/"} className="dropdown-item p-2 py-2 d-flex align-items-center flex-row" onClick={handleCloseNavModal}>
                  <FaHouse size={20}/>
                  <span className="ms-2" style={textStyle}>Home</span>
                </Link>

                <Link to={"/members"} className="dropdown-item p-2 py-2" onClick={handleCloseNavModal}>
                  <FaUsers size={20}/>
                  <span className="ms-2" style={textStyle}>Members</span>
                </Link>

                <Link to={"/settings"} className="dropdown-item p-2 py-2" onClick={handleCloseNavModal}>
                  <FaGear size={20}/>
                  <span className="ms-2" style={textStyle}>Settings</span>
                </Link>


              </div>
            </div>
          </div>


        </Modal.Body>
      </Modal>
    </>
  );
}