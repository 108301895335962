import React, {useEffect, useState} from 'react';
import {useAuth} from "../hooks/useAuth";
import '../Custom.css';
import SelectInput from "../components/CustomSelect";
import {useIncome} from "../hooks/useIncome";
import {AddIndependentFundModal} from "../modals/AddIndependentFundModal";
import {useTheme} from "../hooks/useTheme";
import {FaMousePointer, FaPlus, FaPencilAlt} from "react-icons/fa";
import {EditIndependentFundModal} from "../modals/EditIndependentFundModal";
import {IncomeTypeList} from "../components/IncomeTypeList";
import {EditIncomeTypeModal} from "../modals/EditIncomeTypeModal";
import {AddFundToIncomeTypeModal} from "../modals/AddFundToIncomeTypeModal";

export const IncomeSetup = (props) => {
  const {user} = useAuth();
  const [showAddIndependentFundModal, setShowAddIndependentFundModal] = useState(false);
  const [showEditIndependentFundModal, setShowEditIndependentFundModal] = useState(false);
  const [showEditIncomeTypeModal, setShowEditIncomeTypeModal] = useState(false);
  const [showAddFundToIncomeTypeModal, setShowAddFundToIncomeTypeModal] = useState(false);


  const {theme} = useTheme();
  const {
    accounts,
    selectedAccount,
    independentFunds,
    getAccounts,
    getIndependentFunds,
    setSelectedAccount,
    LoadingIndicator,
    accountsLoading,
    independentFundsLoading,
    getIncomeTypes,
    incomeTypes,
    incomeTypesLoading,
    addIndependentFund,
    selectedIndependentFund,
    setSelectedIndependentFund,
    updateIndependentFund,
    deleteIndependentFund,
    selectedIncomeType,
    setSelectedIncomeType,
    incomeTypeToEdit,
    setIncomeTypeToEdit,
    incomeTypeRemainingFunds,
    setIncomeTypeRemainingFunds,
    independentFundToEdit,
    setIndependentFundToEdit,
  } = useIncome();


  useEffect(() => {
    getAccounts();
  }, [])

  useEffect(() => {
    if (selectedAccount) {
      getIndependentFunds(selectedAccount);
      getIncomeTypes(selectedAccount);
    }
  }, [selectedAccount])


  const handleShowAddIndependentFundModal = () => {
    setShowAddIndependentFundModal(true);
  }

  const handleHideAddIndependentFundModal = () => {
    setShowAddIndependentFundModal(false);
  }

  const handleShowEditIndependentFundModal = () => {
    setShowEditIndependentFundModal(true);
  }

  const handleHideEditIndependentFundModal = () => {
    setShowEditIndependentFundModal(false);
  }

  const handleShowEditIncomeTypeModal = () => {
    setShowEditIncomeTypeModal(true);
  }

  const handleHideEditIncomeTypeModal = () => {
    setShowEditIncomeTypeModal(false);
  }

  const handleShowAddFundToIncomeTypeModal = () => {
    setShowAddFundToIncomeTypeModal(true);
  }

  const handleHideAddFundToIncomeTypeModal = () => {
    setShowAddFundToIncomeTypeModal(false);
  }

  const independentFundSelected = (fund) => {
    setSelectedIndependentFund(fund);
    handleShowEditIndependentFundModal();
  }

  const incomeTypeSelected = (incomeType) => {
    setSelectedIncomeType(incomeType);
    handleShowEditIncomeTypeModal();
  }

  return (
    <>
      <AddIndependentFundModal
        show={showAddIndependentFundModal}
        onHide={handleHideAddIndependentFundModal}
        theme={theme}
        selectedAccount={selectedAccount}
        independentFunds={independentFunds}
        addIndependentFund={addIndependentFund}
        getIndependentFunds={getIndependentFunds}
        independentFundSelected={independentFundSelected}
        deleteIndependentFund={deleteIndependentFund}
      />
      <EditIndependentFundModal
        show={showEditIndependentFundModal}
        onHide={handleHideEditIndependentFundModal}
        theme={theme}
        selectedAccount={selectedAccount}
        independentFunds={independentFunds}
        getIndependentFunds={getIndependentFunds}
        selectedIndependentFund={selectedIndependentFund}
        setSelectedIndependentFund={setSelectedIndependentFund}
        updateIndependentFund={updateIndependentFund}
        independentFundToEdit={independentFundToEdit}
        setIndependentFundToEdit={setIndependentFundToEdit}
      />
      <EditIncomeTypeModal
        show={showEditIncomeTypeModal}
        onHide={handleHideEditIncomeTypeModal}
        theme={theme}
        incomeTypeToEdit={incomeTypeToEdit}
        handleShowAddFundToIncomeTypeModal={handleShowAddFundToIncomeTypeModal}
        setIncomeTypeRemainingFunds={setIncomeTypeRemainingFunds}
      />
      <AddFundToIncomeTypeModal
        show={showAddFundToIncomeTypeModal}
        onHide={handleHideAddFundToIncomeTypeModal}
        theme={theme}
        incomeTypeToEdit={incomeTypeToEdit}
        incomeTypeRemainingFunds={incomeTypeRemainingFunds}
        handleShowAddIndependentFundModal={handleShowAddIndependentFundModal}
      />
      <div className="container-fluid">
        <div className={"d-flex justify-items-center align-items-center mb-2"}>
          <h2 className={"m-0"}>
            Income Setup
          </h2>
        </div>
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="form-group">
              <SelectInput
                options={accounts}
                dictKey={"id"}
                dictDescription={"name"}
                search={true}
                placeholder={"Select Account"}
                valueChanged={setSelectedAccount}
                initialValue={selectedAccount}
                forcedValue={selectedAccount}
              />
            </div>
          </div>
        </div>

        <div className="w-100 mt-2">
          <div className="w-100 overflow-auto d-flex row flex-nowrap ps-1">

            {selectedAccount && (
              <>
                {/*<span onClick={handleShowIndependentFundsModal}*/}
                <span
                  className="badge badge-pill me-1 h4 d-flex bg-primary-lt justify-items-center align-items-center cursor-pointer w-fit-content">
                  <FaPlus className={"me-1"}/>
                  Add Income Type
                </span>
                <span
                  onClick={handleShowAddIndependentFundModal}
                  className="badge badge-pill me-1 h4 d-flex bg-primary-lt justify-items-center align-items-center cursor-pointer w-fit-content">
                  {/*<FaPlus className={"me-1"}/>*/}
                  <FaPencilAlt className={"me-1"}/>
                  {/*Add Independent Fund*/}
                  {/*Add Fund*/}
                  Manage Funds
                </span>
                <span
                  className="badge badge-pill me-1 h4 d-flex bg-primary-lt justify-items-center align-items-center cursor-pointer w-fit-content">
                  <FaPlus className={"me-1"}/>
                  Add Income Type Group
                </span>
              </>
            )}
          </div>
        </div>

        <div className="row">
          {!selectedAccount && (
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center"
                 style={{height: '60vh'}}>
              <FaMousePointer size={100} className={"text-primary"}/>
              <h1 className={"m-0 pt-4 text-muted"}>Select Bank Account</h1>
            </div>
          )}

          {/* LOADING INDICATOR */}
          <LoadingIndicator
            text={"Loading"}
            size={60}
            state={accountsLoading || independentFundsLoading || incomeTypesLoading}
          />

        </div>

        {/* INCOME TYPE LIST */}
        <div className="row mb-3">

          {selectedAccount && !(accountsLoading || independentFundsLoading || incomeTypesLoading) && (
            <IncomeTypeList
              incomeTypeSelected={incomeTypeSelected}
              incomeTypes={incomeTypes}
              incomeTypeToEdit={incomeTypeToEdit}
              setIncomeTypeToEdit={setIncomeTypeToEdit}
              showAddFundToIncomeTypeModal={showAddFundToIncomeTypeModal}
            />
          )}
        </div>

      </div>
    </>
  )
}