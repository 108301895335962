import React, {useEffect, useState, useRef} from 'react';
// import {useEnv} from "../hooks/useEnv";
import {useAuth} from "../hooks/useAuth";
// import {useWebsocket} from "../hooks/useWebsocket";
// import {getCookie} from "../func/Func";
import {MemberList} from "../components/MemberList";
import {ContextMenu} from "../components/ContextMenu";
import {AddFamilyModal} from "../modals/AddFamilyModal";
import {useTheme} from "../hooks/useTheme";
import toast from "react-hot-toast";
import {EditFamilyModal} from "../modals/EditFamilyModal";
import {MemberModal} from "../modals/MemberModal";
import {getCookie} from "../func/Func";
// import the Custom.css
import '../Custom.css';

export const Members = (props) => {
    const {user} = useAuth();
    const {theme} = useTheme();
    const [members, setMembers] = useState([]);
    const [families, setFamilies] = useState([]);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showAddFamilyModal, setShowAddFamilyModal] = useState(false);
    const [showMemberModal, setShowMemberModal] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);
    const [membersLoading, setMembersLoading] = useState(false);
    const debounceTimeout = useRef(null);
    const [selectedFamily, setSelectedFamily] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [showEditFamilyModal, setShowEditFamilyModal] = useState(false);
    const [memberVisits, setMemberVisits] = useState([]);

    // FILTERS
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('UniqueLidNo');
    const [sortOrder, setSortOrder] = useState('asc');
    const [search, setSearch] = useState('');


    const getMembers = (search_override = null) => {
        let search_str = "";
        if (search_override !== null) {
            search_str = search_override;
        } else {
            search_str = search;
        }
        setMembersLoading(true);
        fetch(`/apiv2/members/?page=${page}&sort_order=${sortOrder}&sort_by=${sortBy}&search=${search_str}`, {
            method: 'GET',
        }).then(res => res.json())
            .then(data => {
                if (data?.total_pages) {
                    setTotalPages(data.total_pages);
                    setMembers(data?.members);
                    // setPage(data?.page);
                }
                setMembersLoading(false);
            })
            .catch(err => {
                console.log(err);
                setMembersLoading(false);
            })
    }

    const getMember = (member_id) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/member/${member_id}/`, {
                method: 'GET',
            }).then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    const getFamily = (family_id) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/family/${family_id}/`, {
                method: 'GET',
            }).then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    useEffect(() => {
        getMembers();
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);


    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        getMembers();
    }, [sortBy, sortOrder, page])

    const selectedFamilyChanged = (family_id) => {
        getFamily(family_id)
            .then(data => {
                if (data?.success) {
                    setSelectedFamily(data?.data);
                } else {
                    toast.error("Failed to get family data")
                }
            }).catch(err => {
            console.log(err);
        });
    }

    const selectedMemberChanged = (member) => {
        getMember(member?.id)
            .then(data => {
                if (data?.success) {
                    setSelectedMember(data?.data);
                } else {
                    toast.error("Failed to get member data")
                }
            }).catch(err => {
            console.log(err);
        });
        getMemberVisits(member).then(data => {
            if (data?.success) {
                setMemberVisits(data?.data);
            } else {
                toast.error("Failed to get member visits")
            }
        })
    }

    const getMemberVisits = (member) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/member_visits/${member.id}/`, {
                method: 'GET',
            }).then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    const handleShowSearchModal = () => {
        setShowSearchModal(true);
    }

    const handleCloseSearchModal = () => {
        setShowSearchModal(false);
    }

    const handleShowAddFamilyModal = () => {
        setShowAddFamilyModal(true);
    }

    const handleCloseAddFamilyModal = () => {
        setShowAddFamilyModal(false);
    }

    const handleShowEditFamilyModal = () => {
        setShowEditFamilyModal(true);
    }

    const handleCloseEditFamilyModal = () => {
        setShowEditFamilyModal(false);
    }

    const handleShowMemberModal = () => {
        setShowMemberModal(true);
    }

    const handleCloseMemberModal = () => {
        setShowMemberModal(false);
    }

    const rowRightClick = (e, member) => {
        console.log(member);
        e.preventDefault();
        setContextMenuX(e.clientX);
        setContextMenuY(e.clientY);
        setShowContextMenu(true);
        selectedMemberChanged(member);
    }

    const rowClick = (e, member) => {
        setSelectedFamily(null);
        console.log(member);
        // selectedMemberChanged(member);
        selectedFamilyChanged(member?.family_id);
        setShowEditFamilyModal(true);
    }


    const handleCloseContextMenu = () => {
        setShowContextMenu(false);
    }

    const saveEditedMember = (member) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/member/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: JSON.stringify(member)
            }).then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    // const searchChanged = (e) => {
    //   setSearch(e.target.value);
    // }


    const searchChanged = (e) => {
        const value = e.target.value;
        setSearch(value);

        // Clear the previous timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to call the search function after 1000ms
        debounceTimeout.current = setTimeout(() => {
            getMembers(value);
        }, 1000);
    }

    return (
        <>
            <AddFamilyModal
                show={showAddFamilyModal}
                onShow={handleShowAddFamilyModal}
                onHide={handleCloseAddFamilyModal}
                user={user}
            />
            <EditFamilyModal
                show={showEditFamilyModal}
                onShow={handleShowEditFamilyModal}
                onHide={handleCloseEditFamilyModal}
                selectedFamily={selectedFamily}
                selectedMember={selectedMember}
                theme={theme}
                selectedMemberChanged={selectedMemberChanged}
                handleShowMemberModal={handleShowMemberModal}
                setSelectedFamily={setSelectedFamily}

            />
            <MemberModal
                show={showMemberModal}
                onShow={handleShowMemberModal}
                onHide={handleCloseMemberModal}
                selectedMember={selectedMember}
                setSelectedMember={setSelectedMember}
                theme={theme}
                memberVisits={memberVisits}
                saveEditedMember={saveEditedMember}
                selectedFamilyChanged={selectedFamilyChanged}
            />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-container">
                            <div className="card">
                                <MemberList
                                    members={members}
                                    showSearchModal={showSearchModal}
                                    handleShowSearchModal={handleShowSearchModal}
                                    handleCloseSearchModal={handleCloseSearchModal}
                                    rowRightClick={rowRightClick}
                                    rowClick={rowClick}
                                    handleCloseContextMenu={handleCloseContextMenu}
                                    handleShowAddFamilyModal={handleShowAddFamilyModal}
                                    page={page}
                                    setPage={setPage}
                                    totalPages={totalPages}
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    setSearch={setSearch}
                                    searchChanged={searchChanged}
                                    search={search}
                                    selectedMemberChanged={selectedMemberChanged}
                                    theme={theme}
                                />
                            </div>
                            {membersLoading && (
                                <div className={`card-overlay-${theme}`}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}