import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';

export const ContextMenu = (props) => {
  const {theme} = props;
  const {
    show,
    handleCloseContextMenu,
    positionX,
    positionY,
    buttons,
  } = props;
  let shadowStyle = {};

  if (theme === 'dark') {
    shadowStyle = {
      boxShadow: '0 0 14px -1px rgba(255,255,255,0.5)'
    }
  } else {
    shadowStyle = {
      boxShadow: '0 0 20px -1px rgba(0,0,0,0.5)'
    }
  }


  return (
    <>
      <Modal
        size={'sm'}
        show={show}
        onHide={handleCloseContextMenu}
        animation={false}
        backdropClassName={"bg-transparent"}
      >
        <Modal.Body
          style={{position: "fixed", top: positionY, left: positionX}}
          className={`p-0 ${theme === 'dark' ? 'bg-dark' : 'bg-white'}`}
        >
          {/*<div className="list-group shadow" style={{boxShadow: '0 0 10px rgba(0,0,0,0.5)'}}>*/}
          <div className="list-group" style={shadowStyle}>
            {buttons}
            {/*<a href="#" className="list-group-item list-group-item-action" onClick={handleCloseContextMenu}>Edit</a>*/}
            {/*<a href="#" className="list-group-item list-group-item-action" onClick={handleCloseContextMenu}>Delete</a>*/}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );


}