import React, {useEffect, useState} from 'react'
import {FaSave, FaUserPlus} from "react-icons/fa";
import {AddAdditionalFamilyMemberModal} from "../modals/AddAdditionalFamilyMemberModal"
import {getCookie} from "../func/Func";


export default function FamilyTab(props) {
    console.log("props",props)
    const {
        show,
        onShow,
        onHide,
        user,
        selectedFamily,
        setSelectedFamily,
        theme,
        handleShowMemberModal,
        selectedMemberChanged,
        setSelectedMemberChanged,


    } = props;

    const [showMemberModal, setShowMemberModal] = useState(false);
    // const [members, setMembers] = useState([]);
    const [localSelectedMember, setLocalSelectedMember] = useState(null);
    const [family, setFamily] = useState(props.selectedFamily);
    const [selectedMember, setSelectedMember] = useState(null);
    // const [members, setMembers] = useState(selectedFamily?.members || []);
    const [members, setMembers] = useState([]);

    useEffect(() => {
        setFamily(props.selectedFamily);
    }, [props.selectedFamily]);

    const MemberTypeDisplay = ({member}) => {
        if (member?.member_type === 'Head') {
            // return <span className={"badge bg-warning-lt"}>{member?.member_type}</span>
            return <h4 className={"badge bg-success-lt mb-0 h4"}>{member?.member_type}</h4>
        } else if (member?.member_type === 'Spouse') {
            return <h4 className={"badge bg-warning-lt mb-0 h4"}>{member?.member_type}</h4>
        }
        return <h4 className={"badge bg-info-lt mb-0 h4"}>{member?.member_type}</h4>
    }


    const memberClicked = (member) => {
        console.log('Member Clicked', member);
        selectedMemberChanged(member);
        handleShowMemberModal();
    }
    const onMemberModalHide = () => {
        setShowMemberModal(false);
    }

    const onAddMember = () => {
        let random_id = Math.floor(Math.random() * 1000000);
        let new_member = {
            id: random_id,
            first_name: "",
            last_name: "",
            dob: "",
            type: "Child",
            ftype: "Head",
            familyid: null
        }
        setMembers([...members, new_member]);
        setSelectedMember(new_member);
        setShowMemberModal(true);

    }

    return (
        <>
            <AddAdditionalFamilyMemberModal
                show={showMemberModal}
                onHide={onMemberModalHide}
                members={members}
                setMembers={setMembers}
                selectedMember={selectedMember}
                setSelectedMember={setSelectedMember}
                selectedFamily={selectedFamily}
                setSelectedFamily={setSelectedFamily}
            />
            <div className="row">
                <div className="col-lg-12">
                    <button className={" btn btn-outline-primary"}>
                        <FaUserPlus size={20}/>
                        <span onClick={onAddMember} className={"ms-2"}>Add Member</span>
                    </button>
                </div>
            </div>
            <div className="row mt-2">
                {/*{[...selectedFamily?.members, selectedMember].map((member, memberIndex) =>{*/}
                {selectedFamily?.members?.map((member, memberIndex) => {
                    return (
                        <>
                            <div className="col-lg-4 mt-3">
                                <div className="card bg-primary-lt cursor-pointer" onClick={() => memberClicked(member)}>
                                    <MemberTypeDisplay member={member}/>
                                    {/*<h4 className={"badge bg-warning-lt mb-0 h4"}>{member?.member_type}</h4>*/}
                                    <div className="card-body p-3 pt-1">
                                        {/*<h4 className={"mb-1"}>*/}
                                        {/*  {member?.FullNames} {member?.Surname}*/}
                                        {/*</h4>*/}
                                        <table className="table table-borderless table-sm">
                                            <tbody>
                                            <tr>
                                                <td>Member No</td>
                                                <td>{member?.UniqueLidNo}</td>
                                            </tr>
                                            <tr>
                                                <td>Names</td>
                                                <td>{member?.Names}</td>
                                            </tr>
                                            <tr>
                                                <td>Full Names</td>
                                                <td>{member?.FullNames}</td>
                                            </tr>
                                            <tr>
                                                <td>Surname</td>
                                                <td>{member?.Surname}</td>
                                            </tr>
                                            <tr>
                                                <td>DOB</td>
                                                <td>{member?.BirthDate} {member?.BirthDate && `(${member?.age})`}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </>
    )
}