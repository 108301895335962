import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaBackspace, FaPiggyBank, FaPlus, FaSave, FaTimes, FaUserPlus, FaUsers} from "react-icons/fa";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";
import {Button} from "react-bootstrap";
import {FaEnvelope, FaHandHoldingDollar, FaRegMessage, FaTelegram, FaWhatsapp} from "react-icons/fa6";

export const RealModalBody = (props) => {
    const {
        show,
        onShow,
        onHide,
        theme,
        banks,
        addAccount,
        accounts,
        user,
    } = props;
    const [accountHolder, setAccountHolder] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);
    const [accountName, setAccountName] = useState(null);
    const [formValid, setFormValid] = useState(false);
    const [bank, setBank] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedAccountid, setSelectedAccountid] = useState(null);
    const [Transaction, setTransactions] = useState([]);
    const [showTransactionFields, setShowTransactionFields] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [TransactionDate, setTransactionDate] = useState(undefined);
    const [amount, setAmount] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const [addTranButton, setAddTranButton] = useState(true);
    const [isIncome, setIsIncome] = useState(false);
    const [isExpense, setIsExpense] = useState(false);


    const getTransactions_api = (accountId) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_transactions/${accountId}`, {})
                .then(res => res.json())
                .then((data) => {
                    // console.log("data: ", data)
                    if (data.success) {
                        resolve(data.data)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
    const getTransactions = (accountId) => {
        // getTransactions_api(selectedaccountid)
        getTransactions_api(accountId)
            .then((data) => {
                // console.log("transactions data", data)
                setTransactions(data)
                setIsLoaded(true)

            })
            .catch((err) => {
                // console.log("err", err)
            })
    }

    useEffect(() => {
        if (selectedAccountid) {
            getTransactions(selectedAccountid);
        }
    }, [selectedAccountid]);
    const isFormValid = () => {
        return accountHolder && accountNumber && bank && accountName;
    }


    const valueChanged = (e) => {
        const selectedAccountName = e.target.value;
        const selectedAccount = accounts.find(acc => acc.name === selectedAccountName);
        if (selectedAccount) {
            setSelectedAccount(selectedAccount);
            setSelectedAccountid(selectedAccount.id);
            // console.log("selected account", selectedAccount);
            // console.log("selected account id", selectedAccount.id);
            // console.log("selected Account ID state", selectedAccountid)
            //
            getTransactions(selectedAccountid)
        } else {
            alert("selected account not found", selectedAccount, selectedAccountid)
        }
    }

    const onSaveNewTran = () => {
        let url = `/apiv2/add_transaction/`;
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
                transaction_date: TransactionDate,
                amount: amount,
                description: description,
                account_id: selectedAccountid,
                transactiontype: isIncome ? "income" : "expense",
            }),
        }).then((data) => data.json())
            .then((data) => {
                if (data.success) {
                    toast.success('Transaction saved successfully');
                    getTransactions(selectedAccountid)
                    setShowTransactionFields(false)
                    setAddTranButton(true)
                } else {
                    toast.error('Failed to save transaction');
                }
            })
    }
    const AddTransaction = () => {
        if (selectedAccountid === null || selectedAccountid === undefined) {
            setShowTransactionFields(true)
            setAddTranButton(false)
            setSelectedAccountid(1)
            setSelectedAccount(accounts[0])
            setTransactionDate(undefined)
            setAmount(undefined)
            setDescription(undefined)
            setIsIncome(false)
            setIsExpense(false)
        } else {
            setShowTransactionFields(true)
            setAddTranButton(false)
            setTransactionDate(undefined)
            setAmount(undefined)
            setDescription(undefined)
            setIsIncome(false)
            setIsExpense(false)
        }}
    const onBackButton = () => {
        setShowTransactionFields(false)
        setAddTranButton(true)
    }

        const newTrandate = (e) => {
            selectedAccountid ? (setTransactionDate(e.target.value)) :
                setSelectedAccountid(1)
            setSelectedAccount(accounts[0])

        }
        const newAmount = (e) => {
            setAmount(e.target.value)
        }
        const newDescription = (e) => {
            setDescription(e.target.value)
        }
        const onIncomeClick = (e, isincome) => {
            setIsIncome(e.target.checked)
            // console.log("email:", e.target.checked)
            setIsExpense(false)
        }
        const onExpenseClick = (e, isexpense) => {
            setIsExpense(e.target.checked)
            setIsIncome(false)
        }

        return (
            <>
                <Modal.Body className={`box-shadow-${theme} rounded-3`}>
                    <div className="row">
                        <div className="d-flex col-lg-12">

                            <h3>
                                Latest Transactions {showTransactionFields && <p>{selectedAccount?.name}</p>}
                                {/*<span className={"badge bg-primary-lt ms-2"}>{selectedFamily?.members?.length} Member(s)</span>*/}
                            </h3>
                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
                        </div>
                    </div>
                    <div className="pt-0">
                        <div className="form-group">

                            {isLoaded ? (
                                    <table style={{width: '100%', borderCollapse: 'collapse'}}>
                                        <tr>
                                            <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>Date</th>
                                            <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>Description</th>
                                            <th style={{flex: 1, textAlign: 'right', padding: '8px'}}>Amount</th>
                                            <th style={{flex: 1, textAlign: 'right', padding: '8px'}}>Balance</th>


                                        </tr>

                                        {Transaction.slice(-10).map((transaction, index) => {
                                            if (Transaction.map.length === 0) {
                                                return <li>No Transactions</li>
                                            } else {
                                                return (

                                                    <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : '', justifyContent: "space-between"}}>
                                                        <td>{transaction.transaction_date}</td>
                                                        <td style={{marginLeft: 10, textTransform: "capitalize"}}>{transaction.description}</td>
                                                        {transaction.transaction_type === 4 ?
                                                            (<td style={{textAlign: "right"}}>{Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'}).format(transaction.amount)}</td>)
                                                            : <td style={{color: "orangered", textAlign: "right"}}>-{Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'}).format(transaction.amount)}</td>}
                                                        {transaction.balance > 0 ?
                                                            (<td style={{textAlign: "right"}}>{Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'}).format(transaction.balance)}</td>)
                                                            : (<td style={{textAlign: "right"}}>-{Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'}).format(transaction.balance)}</td>)}

                                                    </tr>
                                                )
                                            }
                                        })}
                                    </table>)
                                : (
                                    <div></div>
                                )
                            }
                        </div>

                        <form action="#" className={"row"}>
                            {addTranButton && <div className="form-group">
                                <label htmlFor="accounts">Select Account</label>
                                <select name="accounts" value={selectedAccount?.name || ''} onChange={valueChanged} className="form-control">
                                    {accounts.map((acc, index) => (
                                        <option key={index} value={acc.name}>{acc.name}</option>
                                    ))}
                                </select>
                            </div>}
                            {addTranButton && <div className="col-lg-12">
                                <div style={{margin: 10}}>
                                    <Button variant="info" onClick={AddTransaction}>
                                        <FaPlus/>
                                        Add Transaction
                                    </Button>
                                </div>

                            </div>}
                            {showTransactionFields && (
                                <div className="col-lg-12">
                                    <div className="form-group row" style={{marginBottom: 10}}>
                                        <label>Date</label>
                                        <input type="date" name="transaction_date" onChange={(e) => newTrandate(e)}/>
                                    </div>
                                    <div className="form-group row" style={{marginBottom: 10}}>
                                        <label>Amount</label>
                                        <input type="number" name="newAmount" onChange={(e) => newAmount(e)}/>
                                    </div>
                                    <div className="form-group row" style={{marginBottom: 10}}>
                                        <label>Description</label>
                                        <input type="text" name="newDescription" onChange={(e) => newDescription(e)}/>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: "left"}}>
                                        <h3 style={{marginRight: 20, alignContent: "center"}}>Select Transaction Type: </h3>
                                        <label>
                                        <span style={{marginRight: 20, cursor: "pointer"}}>
                                            <input style={{display: "none", marginRight: 5}} type="checkbox" id="income" name="income" value="income" checked={isIncome} onClick={(e) => onIncomeClick(e, isIncome)}/>
                                            Income: <FaPiggyBank color={isIncome ? "green" : "grey"} size={30} title={"Income"}/>
                                        </span>
                                        </label>
                                        <label>
                                        <span style={{marginRight: 20, cursor: "pointer"}}>
                                            <input style={{display: "none", marginRight: 5}} type="checkbox" id="expense" name="expense" value="expense" checked={isExpense} onClick={(e) => onExpenseClick(e, isExpense)}/>
                                            Expense: <FaHandHoldingDollar color={isExpense ? "orangered" : "grey"} size={30} title={"Expense"}/>
                                        </span>
                                        </label>
                                    </div>
                                    <div style={{margin: 10, justifyContent:"space-between", display:"flex"}} onClick={onSaveNewTran}>
                                        <Button variant="info">
                                            <FaSave size="20px" style={{marginRight: "10px"}}/>
                                            Save
                                        </Button>
                                        <Button variant="danger" onClick={onBackButton}>
                                            <FaBackspace size="20px" style={{marginRight: "10px"}}/>
                                            Back
                                        </Button>
                                    </div>

                                </div>

                            )}
                        </form>
                    </div>

                </Modal.Body>
            </>
        );
    }

    export const AddTransactionsModal = (props) => {
        let {show, onShow, onHide, theme} = props;
        return <>
            <Modal
                size={'md'}
                show={show}
                onHide={onHide}
                // centered={true}
                // className={`modal-blur`}
                className={`modal-custom-backdrop-${theme}`}
                backdrop={"static"}
                // dialogClassName={`box-shadow-${theme}`}

            >
                {show && (
                    <RealModalBody {...props}/>
                )}
            </Modal>
        </>
    }