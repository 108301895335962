import React, {useEffect, useState} from 'react';
import {useEnv} from "../hooks/useEnv";
import {useAuth} from "../hooks/useAuth";
import {useWebsocket} from "../hooks/useWebsocket";
import {useNavigate} from "react-router-dom";
import {useTheme} from "../hooks/useTheme";
import {FaPlus, FaUserCog, FaUserEdit} from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import {AddAccountModal} from "../modals/AddAccountModal";
import '../Custom.css';
import toast from "react-hot-toast";
import {getCookie} from "../func/Func";
import {EditAccountModal} from "../modals/EditAccountModal";
import {AddTransactionsModal} from "../modals/AddTransactionsModal";

export const Accounts = (props) => {
  const navigate = useNavigate();
  const {user} = useAuth();
  // const {ws_send, ws_status, message, initWebsocket, ws_connected} = useWebsocket(env);
  const [banks, setBanks] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const [showTransactionsModal, setShowTransactionsModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const {theme} = useTheme();


  useEffect(() => {
    getBanks();
    getAccounts();
  }, [])

  const getBanks = () => {
    fetch(`/apiv2/banks/`, {
      method: 'GET',
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          setBanks(data?.data);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const getAccounts = () => {
    fetch(`/apiv2/bank_accounts/`, {
      method: 'GET',
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          setAccounts(data?.data);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const addAccount = (account) => {
    fetch(`/apiv2/bank_accounts/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken')
      },
      body: JSON.stringify(account)
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          getAccounts();
          setShowAddAccountModal(false);
          toast.success("Account added successfully");
        } else {
          toast.error(data?.message);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const saveAccount = (account) => {
    fetch(`/apiv2/bank_accounts/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken')
      },
      body: JSON.stringify(account)
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          getAccounts();
          setShowAddAccountModal(false);
          toast.success("Account saved successfully");
        } else {
          toast.error(data?.message);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const deleteAccount = (account) => {
    fetch(`/apiv2/bank_accounts/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken')
      },
      body: JSON.stringify(account)
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          getAccounts();
          setShowAddAccountModal(false);
          toast.success("Account deleted successfully");
        } else {
          toast.error(data?.message);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const handleShowAddAccountModal = () => {
    setShowAddAccountModal(true);
  }

    const handleCloseAddAccountModal = () => {
    setShowAddAccountModal(false);
  }


  const handleShowTransactionsModal = () => {
    setShowTransactionsModal(true);
  }
  const handleCloseTransactionsModal = () => {
    setShowTransactionsModal(false);
  }

  const handleShowEditAccountModal = () => {
    setShowEditAccountModal(true);
  }

  const handleCloseEditAccountModal = () => {
    setShowEditAccountModal(false);
  }

  const selectedAccountChanged = (account) => {
    setSelectedAccount(account);
    handleShowEditAccountModal();
  }

  const BankAccountDisplay = (props) => {
    const {account} = props;
    const [showAccountNumber, setShowAccountNumber] = useState(false);

    const toggleShowAccountNumber = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowAccountNumber(!showAccountNumber);
    }

    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
        <div onClick={() => selectedAccountChanged(account)} className={`card mb-3 box-shadow-${theme} cursor-pointer`}>
          <h4 className={`text-center h3 bg-primary-lt p-1 m-0 rounded-top`}>{account?.name}</h4>
          <div className={`card-body`}>
            <table className={`table table-striped table-borderless`}>
              <tr>
                <td className={"fw-bold"}>Bank</td>
                <td>{account?.bank?.name}</td>
              </tr>
              <tr>
                <td className={"fw-bold"}>Branch Code</td>
                <td>{account?.bank?.branch_code}</td>
              </tr>
              <tr>
                <td className={"fw-bold"}>Account Holder</td>
                <td>{account?.account_holder}</td>
              </tr>
              <tr>
                <td className={"fw-bold"}>Account Number</td>
                <td>
                  {account?.decrypted_account_number ? (
                    <>
                      {showAccountNumber ? (
                        <span className={"badge bg-danger-lt p-1 cursor-pointer"}
                              onClick={toggleShowAccountNumber}>{account?.decrypted_account_number}</span>
                      ) : (
                        <span className={"badge bg-success-lt p-1 cursor-pointer"}
                              onClick={toggleShowAccountNumber}>**********</span>
                      )}
                    </>
                  ) : (
                    <span className={"badge bg-danger-lt p-1 cursor-pointer"}>No account number</span>
                  )}

                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
    <AddAccountModal
        show={showAddAccountModal}
        onShow={handleShowAddAccountModal}
        onHide={handleCloseAddAccountModal}
        theme={theme}
        banks={banks}
        addAccount={addAccount}
      />
    <AddTransactionsModal
        show={showTransactionsModal}
        onShow={handleShowTransactionsModal}
        onHide={handleCloseTransactionsModal}
        theme={theme}
        banks={banks}
        addAccount={addAccount}
        accounts={accounts}
      />
      <EditAccountModal
        show={showEditAccountModal}
        onShow={handleShowEditAccountModal}
        onHide={handleCloseEditAccountModal}
        theme={theme}
        banks={banks}
        selectedAccount={selectedAccount}
        saveAccount={saveAccount}
        deleteAccount={deleteAccount}
      />

      <div className="container-fluid">
        <div className={"d-flex justify-items-center align-items-center mb-3"}>
          <h2 className={"m-0"}>
            Accounts
          </h2>
          <span
            className={"ms-2 badge badge-pill bg-primary-lt p-2 d-flex justify-items-center align-items-center cursor-pointer"}
            onClick={handleShowAddAccountModal}
          >

            <FaPlus/>
            <span className={"ms-1"}>Add Account</span>
          </span>
          <span
            className={"ms-2 badge badge-pill bg-primary-lt p-2 d-flex justify-items-center align-items-center cursor-pointer"}
            onClick={handleShowTransactionsModal}
          >

            <GrTransaction/>
            <span className={"ms-1"}>Transactions</span>
          </span>
        </div>
        <div className="row">
          {accounts?.map((account, accountIndex) => {
            return (
              <BankAccountDisplay key={accountIndex} account={account}/>
            )
          })}
        </div>
      </div>
    </>
  )
}