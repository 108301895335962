import React, {useState, useEffect, Fragment} from 'react';
import {getCookie} from "../func/Func";
import {FaEnvelope, FaRegMessage} from "react-icons/fa6"
import {BirthdayMessageModal} from "../modals/BirthdayMessageModal"
import {MessagesSent} from "../modals/MessagesSent";
import {AddFamilyModal} from "../modals/AddFamilyModal";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import "react-bootstrap"


const BirthdayReminder = (user = {user}) => {
        const [isLoaded, setIsLoaded] = useState(false);
        const [birthdays, setBirthdays] = useState([]);
        const [showbirthdaymsgmodal, setShowbirthdaymsgmodal] = useState(false);
        const [selectedbirthday, setSelectedbirthday] = useState(undefined);
        const [selectedbirthdayid, setSelectedbirthdayid] = useState(undefined);
        const [showMessageSentModal, setShowMessageSentModal] = useState(false);

        useEffect(() => {
            getBirthdays()
        }, []);



        const getBirthdays_api = (company_id) => {
            return new Promise((resolve, reject) => {
                fetch(`/apiv2/get_birthdays/${company_id}`, {}).then(res => res.json())
                    // .then((data) => data.json())
                    .then((data) => {
                        if (data.success) {
                            resolve(data.data)
                        } else {
                            reject(data)
                        }
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        }
        const getBirthdays = () => {
            getBirthdays_api(user.user.company_id)
                .then((data) => {
                    setBirthdays(data)
                    setIsLoaded(true)
                })
                .catch((err) => {
                    alert(err)
                })
        }
        const handleShowBirthdayMessageModal = () => {
            setShowbirthdaymsgmodal(true);
        }

        const handleCloseBirthdayMessageModal = () => {
            setShowbirthdaymsgmodal(false);

        }
        const handleShowMessagesSentModal = (e, bd) => {
            setShowMessageSentModal(true);
            setSelectedbirthdayid(function (prevState) {
                var newMsg = bd.id

                return newMsg
            })

        }

        const handleCloseMessagesSentModal = () => {
            setShowMessageSentModal(false);
        }

        const sendemail = (e, bd) => {
            console.log("sendemail: ", e, bd)
            setSelectedbirthday(function (prevState) {
                var newBd = bd
                setShowbirthdaymsgmodal(true)
                return newBd;
            });
        }

        return (
            <div>
                {isLoaded ? (
                    <span>
                        <h1>Upcoming Birthdays</h1>
                        <table style={{width: '100%', borderCollapse: 'collapse'}}>
                            <tr>
                                    <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>Name</th>
                                    <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>BirthDate</th>
                                    <th style={{flex: 0.5, textAlign: 'left', padding: '8px'}}>Age</th>
                                    <th style={{flex: 0.5, textAlign: 'left', padding: '2px'}}>Message</th>
                            </tr>
                            {birthdays.map((bd, index) => {
                                return (
                                    <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : '', justifyContent: "space-between"}}>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id={'tooltip-top'}>See Messages Sent</Tooltip>}
                                        >
                                            <td onClick={(e) => handleShowMessagesSentModal(e, bd)}>{bd.Names} {bd.Surname} </td>
                                        </OverlayTrigger>
                                        <td onClick={(e) => handleShowMessagesSentModal(e, bd)}>{bd.BirthDate}</td>
                                        <td onClick={(e) => handleShowMessagesSentModal(e, bd)}>{bd.age}</td>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id={'tooltip-top'}>Send a Birthday Message</Tooltip>}
                                        >
                                            <td className="col-6 d-flex justify-content-center align-items-center"
                                                onClick={(e) => sendemail(e, bd)}>
                                                <FaEnvelope color={"#0ca678"} size={15}/>
                                            </td>
                                        </OverlayTrigger>


                                    </tr>
                                );
                            })}
                    </table>
                        {selectedbirthday && <BirthdayMessageModal
                            selectedbirthday={selectedbirthday}
                            show={showbirthdaymsgmodal}
                            onShow={handleShowBirthdayMessageModal}
                            onHide={handleCloseBirthdayMessageModal}
                            user={user}
                        />
                        }
                        <MessagesSent
                            show={showMessageSentModal}
                            onShow={handleShowMessagesSentModal}
                            onHide={handleCloseMessagesSentModal}
                            selectedbirthdayid={selectedbirthdayid}
                        />
                    </span>

                ) : (
                    <p>Loading...</p>
                )}
            </div>
        );
    }
;

export default BirthdayReminder;
