import React, {useState, useEffect, Fragment} from 'react';
import {getCookie} from "../func/Func";
import {FaEnvelope, FaRegMessage} from "react-icons/fa6"
import {BirthdayMessageModal} from "../modals/BirthdayMessageModal"
import {MessagesSent} from "../modals/MessagesSent";
import {AddFamilyModal} from "../modals/AddFamilyModal";
import {AddAttendeesModal} from "../modals/AddAttendeesModal";


const UpComingEvents = (user = {user}) => {
        const [isLoaded, setIsLoaded] = useState(false);
        const [events, setEvents] = useState([]);
        const [showAttendeesModal, setShowAttendeesModal] = useState(false);
        const [eventId, setEventId] = useState(undefined);

        useEffect(() => {
            getUpcomingEvents()
        }, []);


        const getBirthdays_api = (company_id) => {
            return new Promise((resolve, reject) => {
                fetch(`/apiv2/get_upcoming_events/${company_id}`, {}).then(res => res.json())
                    // .then((data) => data.json())
                    .then((data) => {
                        if (data.success) {
                            resolve(data.data)
                        } else {
                            reject(data)
                        }
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        }
        const getUpcomingEvents = () => {
            getBirthdays_api(user.user.company_id)
                .then((data) => {
                    setEvents(data)
                    setIsLoaded(true)
                })
                .catch((err) => {
                    alert(err)
                })
        }
        const handleCloseAttendeesModal = () => {
            setShowAttendeesModal(false);
        }

        const handleShowAttendeesModal = (e, event) => {
            setShowAttendeesModal(true);
            setEventId(event)
        }

        return (
            <div>
                {isLoaded ? (
                    <span>
                        <h1>Upcoming Events</h1>
                        <table style={{width: '100%', borderCollapse: 'collapse'}}>
                            <tr>
                                    <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>Event Description</th>
                                    <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>Start Date</th>
                                    <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>End Date</th>
                                    <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>Contact Person</th>
                                    <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>Cost</th>
                                    <th style={{flex: 1, textAlign: 'left', padding: '8px'}}>Venue</th>
                            </tr>
                            {events.map((event, index) => {
                                return (
                                    <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : '', justifyContent: "space-between"}}
                                        onClick={(e) => handleShowAttendeesModal(e, event)}>
                                        <td style={{padding: '8px'}}>{event.description}</td>
                                        <td style={{padding: '8px'}}>{new Date(event.startdate).toLocaleString("en-GB", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: false,
                                        })}</td>
                                        <td style={{padding: '8px'}}>{new Date(event.startdate).toLocaleString("en-GB", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: false,
                                        })}</td>
                                        <td style={{padding: '8px'}}>{event.contactperson}</td>
                                        <td style={{padding: '8px'}}>{event.cost}</td>
                                        <td style={{padding: '8px'}}>{event.venue}</td>
                                    </tr>
                                )
                            })}
                    </table>
                        <AddAttendeesModal
                            show={showAttendeesModal}
                            onShow={handleShowAttendeesModal}
                            onHide={handleCloseAttendeesModal}
                            eventId={eventId}
                            user={user}
                        />
                    </span>

                ) : (
                    <p>Loading...</p>
                )}
            </div>
        );
    }
;

export default UpComingEvents;
