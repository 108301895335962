import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaSave, FaTimes, FaUserPlus, FaUsers} from "react-icons/fa";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";

export const RealModalBody = (props) => {
  const {
    show,
    onShow,
    onHide,
    theme,
    banks,
    addAccount,
  } = props;
  const [accountHolder, setAccountHolder] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [bank, setBank] = useState(null);

  const accountHolderChanged = (e) => {
    setAccountHolder(e.target.value);
  }

  const accountNameChanged = (e) => {
    setAccountName(e.target.value);
  }

  const accountNumberChanged = (e) => {
    setAccountNumber(e.target.value);
  }

  const bankChanged = (e) => {
    let bank_json = JSON.parse(e.target.value);
    setBank(bank_json);
  }

  const isFormValid = () => {
    return accountHolder && accountNumber && bank && accountName;
  }

  const saveAccount = () => {
    if(!isFormValid()){
      toast.error("Please fill in all fields");
      return;
    }
    let account = {
      account_holder: accountHolder,
      account_number: accountNumber,
      bank_id: bank?.id,
      name: accountName,
    }
    addAccount(account);
  }

  return (
    <>
      <Modal.Body className={`box-shadow-${theme} rounded-3`}>
        <div className="row">
          <div className="d-flex col-lg-12">

            <h3>
              Add Account
              {/*<span className={"badge bg-primary-lt ms-2"}>{selectedFamily?.members?.length} Member(s)</span>*/}
            </h3>
            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
          </div>
        </div>
        <div className="pt-0">
          <form action="#" className={"row"}>
            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="name">Account Name</label>
                <input onInput={accountNameChanged} value={accountName?accountName:''} required={true} type="text" className="form-control" id="name" placeholder="Account Name"/>
              </div>
            </div>

            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="name">Account Holder</label>
                <input onInput={accountHolderChanged} value={accountHolder?accountHolder:''} required={true} type="text" className="form-control" id="name" placeholder="Account Holder"/>
              </div>
            </div>

            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="name">Account Number</label>
                <input onInput={accountNumberChanged} value={accountNumber?accountNumber:''} required={true} pattern="\d+" type="password" className="form-control" id="name" placeholder="Account Number"/>
              </div>
            </div>

            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="name">Bank</label>
                <select onInput={bankChanged} name="" id="" className="form-control">
                  {!bank && (
                    <option disabled={true} selected={true} value="">Select Bank</option>
                  )}
                  {banks?.map((bank, index) => (
                    <option key={index} value={JSON.stringify(bank)}>{bank.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="name">Branch Code</label>
                <input type="text" value={bank?.branch_code} readOnly={true}
                       className="form-control cursor-not-allowed" id="name" placeholder="Branch Code"/>
              </div>
            </div>

            <div className="col-lg-12">
              <button onClick={saveAccount} type={'button'} disabled={!isFormValid()} className={`btn btn-${isFormValid()?'primary':'secondary'} w-100`}>
                <FaSave className={"me-2"}/>
                Save
              </button>
            </div>
          </form>
        </div>

      </Modal.Body>
    </>
  );
}

export const AddAccountModal = (props) => {
  let {show, onShow, onHide, theme} = props;
  return <>
    <Modal
      size={'md'}
      show={show}
      onHide={onHide}
      // centered={true}
      // className={`modal-blur`}
      className={`modal-custom-backdrop-${theme}`}
      backdrop={"static"}
      // dialogClassName={`box-shadow-${theme}`}

    >
      {show && (
        <RealModalBody {...props}/>
      )}
    </Modal>
  </>
}