import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaTimes, FaSave, FaPlus} from "react-icons/fa";
import toast from "react-hot-toast";

const tabs = [
  {title: "Add Fund", name: "add_fund"},
  {title: "Fund List", name: "fund_list"},
]

export const RealModal = (props) => {
  const {
    show,
    onShow,
    onHide,
    theme,
    addIndependentFund,
    independentFunds,
    getIndependentFunds,
    selectedAccount,
    independentFundSelected,
    deleteIndependentFund,
    initialTabIndex,
  } = props;
  const [fundName, setFundName] = useState('');
  const [selectedTab, setSelectedTab] = useState(initialTabIndex?tabs[initialTabIndex]:tabs[0]);

  const fundNameChanged = (e) => {
    setFundName(e.target.value);
  }

  const saveFund = () => {
    if (fundName === '') {
      toast.error('Please enter a name');
      return;
    }
    addIndependentFund(fundName).then(data => {
      if(data?.success) {
        toast.success('Fund added successfully');
        setFundName('');
        getIndependentFunds(selectedAccount);
        setSelectedTab(tabs[1]);
      }else{
        toast.error('Failed to add fund');
      }
    }).catch(err => {
      toast.error('Failed to add fund');
    });
  }

  const deleteFund = (fund) => {
    deleteIndependentFund(fund).then(data => {
      if(data?.success) {
        toast.success('Fund deleted successfully');
        getIndependentFunds(selectedAccount);
      }else{
        toast.error(data?.message);
      }
    }).catch(err => {
      toast.error('Failed to delete fund');
    });
  }

  return (
    <>
      <Modal.Body className={`box-shadow-${theme} rounded-3 p-0`}>
        <div className="row px-3 pt-3">
          <div className="d-flex col-lg-12">
            <h3 className={"mb-0"}>
              {selectedTab?.title}
            </h3>
            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
          </div>
        </div>
        <div className="col-lg-12 px-3">
          <div className="w-100 mt-2">
            <div className="w-100 overflow-auto d-flex row flex-nowrap ps-1">
              {tabs.map((tab, tabIndex) => {
                let selected = selectedTab?.name === tab?.name;
                return (
                  <span
                    onClick={() => setSelectedTab(tab)}
                    className={`badge badge-pill me-1 h4 d-flex bg-${selected?'primary':'secondary'}-lt justify-items-center align-items-center cursor-pointer w-fit-content`}>
                      {/*<FaPlus className={"me-1"}/>*/}
                    {tab?.title}
                  </span>
                )
              })}
            </div>
          </div>
        </div>

        {selectedTab?.name === 'add_fund' && (
          <div className="pt-0 row px-3 pb-3">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input onInput={fundNameChanged} value={fundName} type="text" className="form-control" id="name"
                       placeholder="Enter Name"/>
              </div>
            </div>
            <div className="col-12 mt-2">
              <button className="btn btn-primary w-100" onClick={saveFund}>
                <FaSave className={"me-1"}/>
                Save
              </button>
            </div>
          </div>
        )}
        {selectedTab?.name === 'fund_list' && (
          <div className="pt-0 row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {independentFunds?.map((fund, fundIndex) => {
                      return (
                        <tr key={fundIndex}>
                          <td className={"align-content-center"}>
                            <span>{fund?.name}</span>
                          </td>
                          <td className={"p-0 align-content-center"}>
                            <span onClick={() => independentFundSelected(fund)} className="badge p-2 px-3 cursor-pointer badge-pill bg-primary-lt me-1">
                              Edit
                            </span>
                            <span onClick={() => deleteFund(fund)} className="badge p-2 px-3 cursor-pointer badge-pill bg-danger-lt">
                              Delete
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}


      </Modal.Body>

    </>
  );
}

export const AddIndependentFundModal = (props) => {
  let {
    show,
    onHide,
    theme,
  } = props;
  return (
    <Modal
      size={'md'}
      show={show}
      onHide={onHide}
      className={`modal-custom-backdrop-${theme}`}
      backdrop={"static"}
    >
      {show && (
        <RealModal {...props}/>
      )}
    </Modal>
  )
}