import React, {useEffect, useState} from 'react';
import {FaUserPlus, FaSearch} from "react-icons/fa";
import {SearchModal} from "../modals/SearchModal";
import {Paginator} from "./Paginator";
import TableHeadColumn from "./TableHeadColumn";

export const MemberList = (props) => {
  const {
    showSearchModal,
    handleShowSearchModal,
    handleCloseSearchModal,
    togglePrivacyMode,
    rowRightClick,
    rowClick,
    handleCloseContextMenu,
    members,
    page,
    setPage,
    totalPages,
    handleShowAddFamilyModal,
    searchChanged,
    search,
    theme,
    // selectedMemberChanged
  } = props;


  return (
    <>
      <SearchModal
        show={showSearchModal}
        handleCloseSearchModal={handleCloseSearchModal}
        handleShowSearchModal={handleShowSearchModal}
        searchChanged={searchChanged}
        memberSearch={search}
        theme={theme}
      />
      <div className="row">
        <div className="col-lg-12 p-2 px-4">
          <div className="d-flex justify-content-between">
            <div>
              <h2>Members</h2>
            </div>
            <div className={"d-flex align-items-center"}>


              <button onClick={handleShowAddFamilyModal} className="btn btn-primary me-2">
                <FaUserPlus/>
                <span className={"d-none d-lg-block ms-2"}>Add Family</span>
              </button>
              <div className="input-icon d-none d-lg-block">
                <input type="text" onInput={searchChanged} value={search} className="form-control"
                       placeholder="Search…"/>
                <span className="input-icon-addon">
                                    <FaSearch/>
                                </span>
              </div>
              <div className="d-lg-none">
                <button onClick={handleShowSearchModal} className="btn btn-primary">
                  <FaSearch/>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="col-lg-12 table-responsive table-nowrap">
            <table className="table table-hover table-striped">
              <thead>
              <tr>
                {/*<TableHeadColumn text={"ID"} keyName={"id"} {...props}/>*/}
                <TableHeadColumn text={"Lid #"} keyName={"UniqueLidNo"} {...props}/>
                {/*<TableHeadColumn text={"Seq #"} keyName={"SeqNo"} {...props}/>*/}
                <TableHeadColumn text={"Names"} keyName={"Names"} {...props}/>
                <TableHeadColumn text={"Full Names"} keyName={"FullNames"} {...props}/>
                <TableHeadColumn text={"Last Name"} keyName={"Surname"} {...props}/>
                <TableHeadColumn text={"DOB"} keyName={"BirthDate"} {...props}/>
                <TableHeadColumn text={"ID"} keyName={"IdNo"} {...props}/>
              </tr>
              </thead>
              <tbody>

              {members?.map((member, memberIndex) => {
                return (
                  // <tr key={memberIndex} onContextMenu={(e) => rowRightClick(e, member)}>
                  <tr key={memberIndex} onClick={(e) => rowClick(e, member)}>
                    {/*<td>{member?.id}</td>*/}
                    <td>{member?.UniqueLidNo}</td>
                    {/*<td>{member?.SeqNo}</td>*/}
                    <td>{member?.Names}</td>
                    <td>{member?.FullNames}</td>
                    <td>{member?.Surname}</td>
                    <td>{member.BirthDate}</td>
                    <td>{member.IdNo}</td>
                  </tr>
                )
              })}

              </tbody>
            </table>

          </div>
          <div>
            <Paginator
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              className={"btn btn-sm px-2 py-1"}
              containerClassName={"mt-2"}

            />
            <div className="d-flex justify-content-center my-2">
              <span>Page {page} of {totalPages}</span>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}