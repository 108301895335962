import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaTimes, FaSave} from "react-icons/fa";
import toast from "react-hot-toast";
import {getCookie} from "../func/Func";

const ftypes = [
    "Child",
    "Adult",
    "Senior",
]
const types = [
    "Head",
    "Spouse",
    "Child",
]

export const AddAdditionalFamilyMemberModal = (props) => {

    const [error, setError] = useState({isError: false, errorMessage: ""})

    const {
        show,
        onHide,
        members,
        setMembers,
        selectedMember,
        setSelectedMember,
        selectedFamily,
        setSelectedFamily,
        onClick
    } = props;

    console.log(props)

    const onCancel = () => {
        let new_members = members.filter((member) => {
            return member.id !== selectedMember.id;
        });
        setMembers(new_members);
        onHide();
    }

    const valueChanged = (e) => {

        setSelectedMember({
            ...selectedMember,
            [e.target.name]: e.target.value
        });
    }

    const onSave = () => {
        if (!selectedMember?.type) {
            toast.error("Please select a type");
            return;
        }

        console.log("selectedMember", selectedMember);
        console.log("selectedFamily", selectedFamily);

        addAdditionalMembers_api().then((data) => {
            const newMember = data.data;
            console.log("newMember", newMember);
            setSelectedFamily((prevFamily) => {
                return {...prevFamily, members: [...prevFamily.members, newMember]};
            });
        }).catch((err) => {
            console.error("Error adding member", err);
        });
        onHide();
    }

    const addAdditionalMembers_api = async (family_id) => {
        let url = `/apiv2/add_additional_members/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
                family_id: selectedFamily.id,
                new_member: selectedMember
            }),
        })
        if (!response.ok) {
            console.error("error");
        }
        const data = await response.json()
        if (data.success) {
            console.log("success", data);
            return data;
        } else {
            console.error("error"); // data.errorMessage
            throw new Error("error")
        }
    }

    return (
        <>

            <Modal
                size={'lg'}
                show={show}
                onHide={onCancel}
                centered={true}
                className={"modal-blur"}
            >
                <Modal.Body>

                    {error.isError && (
                        <div className="row">
                            {error.errorMessage}
                        </div>)
                    }

                    <div className="row">
                        <div className="d-flex col-lg-12">
                            <h3>
                                Edit Member
                            </h3>
                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onCancel}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="first_name">First Name</label>
                                <input type="text" name="first_name" value={selectedMember?.first_name}
                                       onChange={valueChanged} className="form-control"/>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="last_name">Last Name</label>
                                <input type="text" name="last_name" value={selectedMember?.last_name}
                                       onChange={valueChanged} className="form-control"/>
                            </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="last_name">Date of birth</label>
                                <input type="date" name="dob" value={selectedMember?.dob}
                                       onChange={valueChanged} className="form-control"/>
                            </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="type">Type</label>
                                <select name="type" value={selectedMember?.type} onChange={valueChanged} className="form-control">
                                    {types.map((type, index) => {
                                        return (
                                            <option key={index} value={type}>{type}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        {/*<div className="col-lg-6 mb-2">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label htmlFor="ftype">Family Member Type</label>*/}
                        {/*        <select name="ftype" value={selectedMember?.ftype} onChange={valueChanged} className="form-control">*/}
                        {/*            {ftypes.filter((types)).map((type, index) => {*/}
                        {/*                console.log("type", type)*/}
                        {/*                return (*/}
                        {/*                    <option key={index} value={type}>{type}</option>*/}
                        {/*                )*/}
                        {/*            })}*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        <div className="row mt-2">
                            <div className="col-lg-12">
                                <button className={"btn btn-outline-primary"} onClick={onSave}>
                                    <FaSave size={20} className={"me-2"}/>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}